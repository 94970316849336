import { render, staticRenderFns } from "./Mediator.vue?vue&type=template&id=2dfc4b45&scoped=true"
import script from "./Mediator.vue?vue&type=script&lang=js"
export * from "./Mediator.vue?vue&type=script&lang=js"
import style0 from "./Mediator.vue?vue&type=style&index=0&id=2dfc4b45&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dfc4b45",
  null
  
)

export default component.exports