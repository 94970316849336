<template>
    <div class="drawer" v-bind:class="[{ blue: blue }, { active: active }]">
      <div class="textpadding">
        <div class="toggle" v-on:click="active = !active">
          <h2>{{ title }}</h2>
        </div>
        <div class="toggleContent">
          <p>
            <slot></slot>
          </p>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'TextDrawer',
  props: {
    title: String,
    blue: Boolean,
  },
  data() {
    return {
      active: false,
    };
  },
};
</script>

<style scoped lang="less">
@import "../assets/styling/global.less";

  .drawer {
    width: 100%;
    .toggle {
      cursor: pointer;
      width: 100%;
      position: relative;
      padding-right: 30px;
      &:after {
        content: '+';
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        text-align: center;
        font-size: 20px;
        line-height: 20px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        background-color: @dark-blue;
        color: #FFF;
        transition: all 0.2s ease-in-out;
      }
    }
    .toggleContent {
      transition: all 0.8s cubic-bezier(0,1,0,1);
      overflow: hidden;
      max-height: 1px;
    }
  }

  .drawer.active {
    .toggle:after {
      transform: rotate(45deg);
    }
    .toggleContent {
      max-height: 4000px;
      transition: all 0.8s cubic-bezier(1,0,1,0);
    }
  }

  .blue {
    background-color: @light-blue;
  }
</style>
