<template>
  <div class="mediator">
    <div class="back" v-on:click="goHome">
      <img src="../assets/back.svg" alt="Go back">
      <p>Zurück zur Homepage</p>
    </div>
    <div class="heading">
      <img v-bind:src="jsonData.image" v-bind:alt="jsonData.name">
      <div class="details">
        <h1>{{ jsonData.name }}</h1>
        <a v-bind:href="'mailto:' + jsonData.email"><h2>{{ jsonData.email }}</h2></a>
        <a
          v-bind:href="`https://${jsonData.homepage}`"
          v-if="jsonData.homepage"
          style="margin-top: 4px;"
          target="_blank">
          <h2>{{ jsonData.homepage }}</h2>
        </a>
      </div>
    </div>
    <div class="content textpadding" v-for="(data, index) in jsonData.q_and_a" :key="index">
      <div class="question" v-if="!data.list">
        <h1 class="q">{{ data.q }}</h1>
        <p class="a">
          <span v-for="(line, i) in splitAnswer(data.a)" :key="i">{{ line }}
            <br v-if="i < (splitAnswer(data.a).length - 2)" />
          </span>
        </p>
      </div>
      <div class="question list" v-else>
        <h1 class="q">{{ data.q }}</h1>
        <ul v-for="answer in data.a" :key="answer">
          <li class="a">» {{ answer }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import json from '../../public/mediators.json';

export default {
  name: 'Mediator',
  data() {
    return {
      jsonData: null,
      allData: json,
    };
  },
  created() {
    let mediatorSlug = this.$router.currentRoute.fullPath.split('/')[this.$router.currentRoute.fullPath.split('/').length - 1];
    let found = false;
    json.mediators.forEach((obj) => {
      if (obj.slug === mediatorSlug) {
        this.jsonData = obj;
        found = true;
      }
    });

    mediatorSlug = null;
    if (found === false) {
      this.$router.push({ name: 'Home' });
    }
  },
  methods: {
    goHome() {
      this.$router.push({
        name: 'Home',
      });
    },
    splitAnswer(text) {
      return text.split('\n');
    },
  },
};
</script>

<style lang="less" scoped>
  @import url("../assets/styling/global.less");

  .back {
    display: flex;
    align-items:center;
  }

  @media only screen and (min-width: 801px) {
    .back {
      display: none;
    }
  }

  .heading {
    background-color: @light-blue;
    height: 400px;
    width: 100%;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0 0;
    grid-template-areas:
      "image details";
    align-items: center;
    @media only screen and (max-width: 1000px) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas:
        "image"
        "details";
      height: 600px;
      padding: 0 20px;
    }
    img {
      width: 250px;
      height: 250px;
      grid-area: image;
      justify-self: center;
    }
    .details {
      grid-area: details;
      justify-self: left;
      @media only screen and (max-width: 1000px) {
        justify-self: center;
      }
    }
  }

</style>
