<template>
  <div id="app">
    <SideBar id="sidebar"></SideBar>
    <div id="content">
      <router-view />
    </div>
  </div>
</template>

<script>
import SideBar from './components/SideBar.vue';
import json from '../public/mediators.json';

import Mediator from './views/Mediator.vue';

export default {
  name: 'App',
  components: {
    SideBar,
  },
  data() {
    return {
      newRoutes: [],
    };
  },
  created() {
    json.mediators.forEach((obj) => {
      this.newRoutes.push({
        path: `/mediator/${obj.slug}`,
        name: obj.slug,
        component: Mediator,
      });
      /* Object.entries(obj).forEach(([key, value]) => {
        console.log(`${key} ${value}`);
      }); */
    });
    this.$router.addRoutes(this.newRoutes);
  },
};
</script>

<style lang="less">
  @import "assets/styling/global";
  #sidebar {
    grid-area: sidebar;
    position: sticky;
    top: 0;
  }

  #app {
    max-width: 1280px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 320px 1fr;
    grid-template-rows: 1fr;
    gap: 0 0;
    grid-template-areas:
      "sidebar content";
  }

  @media only screen and (max-width: 800px) {
    #sidebar {
      display: none;
    }

    #app {
      grid-template-areas:
        "content content";
    }
  }

  #content {
    box-shadow: 0 0 20px rgba(0,0,0,0.2);
    grid-area: content;
    z-index: 1;
  }

  #footer {
    background-color: @light-blue;
  }

</style>
