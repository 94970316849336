<template>
  <div class="home">
    <div class="text-padding">
      <br>
      <h1>Mediation Niederrhein</h1>
      <p class="quote">
        Es gibt im Streit keinen Schuldigen – Es gibt nur verschiedene Sichtweisen
      </p>
    </div>
    <TextDrawer title="Wer sind wir und was ist uns wichtig?">
      Wir sind ein Netzwerk von Mediatoren am Niederrhein und gründeten uns im Sommer 2020 nach
      unserer gemeinsamen Ausbildung zum/zur zertifizierten Mediator/in *) durchgeführt von der
      KoViaK Akademie, Monique Ridder und Team, Mülheim **) in Kooperation mit der
      Volkshochschule Gelderland und Bochum.
      <br><br>
      Wichtig ist uns, den Konflikt der Medianden in einem respektvollen
      und wertschätzenden Umgang zu begleiten.
      <br><br>
      Eines unserer Ziele ist es, die Mediation als Form der Konfliktlösung mit all ihren Vorteilen
      bekannter zu machen. In unseren regelmäßigen Treffen entwickeln wir zur Qualitätssicherung
      durch Rollenspiele und Austausch unsere mediativen Kompetenzen weiter.
      <br><br>
      Unser Netzwerk bietet Ihnen in allen Konflikt- und Streitsituationen geeignete
      Mediator/innen ***). Genauere Information zu unseren Mitgliedern finden Sie durch
      einen Klick auf das jeweilige Foto.
      <br><br>
      *) Erfolgreiche Ausbildung zu Mediatoren (zertifiziert nach den Vorgaben des
      Mediationsgesetzes (MediationsG) v. 26.07.2017 und der Rechtsverordnung zu § 6
      MediationsG, zertifizierte Mediations-Ausbildungsverordnung (zMediatAusbV) v. 01.09.2017
      <br><br>
      **) Akademie für Konflikt- und Führungskompetenz (www.koViak.de);
      Ko = Kooperative, Vi = Visionär; ak = akratisch
      <br><br>
      ***) Zur besseren Lesbarkeit verwenden wir im weiteren Verlauf jeweils die männliche Form.
    </TextDrawer>
    <TextDrawer blue title="Was ist Mediation?">
      Mediation ist eine außergerichtliche Form der Konfliktlösung, bei der der
      Mediator die Medianden durch ein strukturiertes Verfahren führt und die
      Konfliktparteien (Medianden) die Lösung selbst erarbeiten.
      <br><br>
      Darin unterscheidet sich Mediation im Wesentlichen von Schlichtungs- oder
      Gerichtsverfahren. Hier wird die Lösung vom Schlichter oder
      Richter vorgegeben. Diese Lösung berücksichtigt häufig nicht
      die Bedürfnisse und Interessen der Konfliktparteien.
      <br><br>
      <span class="underline">Wie kommt es dazu?</span>
      <br>
      Voraussetzung für die Lösungsfindung ist, dass die Medianden zu einem gegenseitigen
      Verständnis ihrer jeweiligen Bedürfnisse und Sichtweisen gelangen. Die Lösungen
      berücksichtigen die Interessen aller Beteiligten und führen somit zu einer individuellen,
      nachhaltigen win-win Situation für die Medianden. Nur wenn alle Parteien mit den erarbeiteten
      Lösungen einverstanden sind, wird eine Vereinbarung geschlossen.
      <br>
      Durch die getroffene Vereinbarung steigt die Wahrscheinlichkeit zur anschließenden Aussöhnung,
      während Gerichtsurteile nicht selten zu Unverständnis und Unzufriedenheit auf mindestens einer
      Seite führen. Das gegenseitige Verständnis und die durch die Medianden erarbeiteten Lösungen
      sind besser als Konflikt und Streit – Mediation ist der erste Schritt zu mehr Zufriedenheit.
      <br><br>
      <a href="https://www.youtube.com/watch?v=k2qXg9u9xkc">
        Hier finden Sie ein Video, welches Mediation erläutert
      </a>
      <br>
      <a href="http://www.gesetze-im-internet.de/mediationsg/index.html">
        Hier finden Sie weitere Informationen zum Mediationsgesetz
      </a>
      <br>
      <br>
      <span class="bold">Mediation ist:</span>
      <br>
      <table>
        <tr>
          <th>Freiwillig</th>
          <th>Die Medianden entscheiden selbst, ob sie an der Mediation teilnehmen.</th>
        </tr>
        <tr>
          <th>Außergerichtlich</th>
          <th>Die gefundenen Lösungen können dennoch rechtssicher vereinbart werden.</th>
        </tr>
        <tr>
          <th>Vertraulich</th>
          <th>
            Alles, was in der Mediation gesagt wird, unterliegt der
            Schweigepflicht des Mediators und der Medianden.
          </th>
        </tr>
        <tr>
          <th>Strukturiert</th>
          <th>Der Mediator führt die Medianden durch die Phasen der Mediation.</th>
        </tr>
        <tr>
          <th>Zukunftsorientiert</th>
          <th>
            Der vorliegende Sachverhalt wird soweit aufgeklärt,
            wie es zum Verständnis für die gegenwärtige Situation notwendig ist.
            Die Lösung bezieht sich auf die Zukunft der Medianden.
          </th>
        </tr>
        <tr>
          <th>Ergebnisoffen</th>
          <th>
            Die Medianden erarbeiten selber Lösungen; das
            Ergebnis ist zu Beginn der Mediation offen.
          </th>
        </tr>
      </table>

      <p class="quote">
        Wenn wir die Vergangenheit mit der Gegenwart streiten
        lassen, werden wir die Zukunft verlieren.
      </p>

    </TextDrawer>
    <TextDrawer title="Welche Rolle hat der Mediator?">
      <ul>
        <li>Der Mediator ist Helfer und Vermittler der Kommunikation.</li>
        <li>
          Er ist allparteilich, das bedeutet, dass er die Interessen
          aller Beteiligten gleichsam berücksichtigt.
        </li>
        <li>
          Der Mediator achtet auf gleiche Redeanteile und darauf, dass die
          Gesprächspartner ausreden und alles ansprechen können,
          was in der Mediation bearbeitet werden soll.
        </li>
        <li>
          Er führt die Gesprächspartner durch die Struktur der Mediation, er hinterfragt
          die einzelnen Gründe und führt zum gegenseitigen Verständnis zwischen den Medianden.
        </li>
        <li>
          Aus dem gegenseitigen Verstehen heraus befähigt er die Medianden, den Schlüssel zur
          Lösung zu finden und kreative Lösungsideen selbst zu erarbeiten.
        </li>
        <li>
          Zum Schluss überprüft er die Lösung/en daraufhin, ob diese ausgewogen
          ist/sind und die Interessen aller Medianden erfüllt werden.
        </li>
        <li>
          Der Mediator macht die Medianden darauf aufmerksam, dass sie sich vor einer
          Vereinbarung rechtlichen Rat einholen können, um auf dieser Basis
          eine informierte Entscheidung treffen zu können.
        </li>
      </ul>
    </TextDrawer>
    <TextDrawer blue title="Welche Rolle haben die Medianden?">
      <ul>
        <li>
          Die Medianden handeln eigenverantwortlich, d.h. sie entscheiden,
          welche Themen sie ansprechen und erarbeiten möchten, die aus ihrer
          Sicht für die Klärung des Konflikts wichtig sind.
        </li>
        <li>
          Die Medianden treten für sich selbst ein, da es um ihre eigenen
          Interessen und Bedürfnisse geht; sie werden nicht vertreten.
        </li>
        <li>
          Die Medianden erarbeiten kreative Lösungsideen und entwickeln
          daraus ihre individuellen Lösungen (Win-Win-Situation).
        </li>
      </ul>
    </TextDrawer>
    <TextDrawer title="Welche Vorteile und Chancen bietet Mediation?">
      <ul>
        <li>
          Ein Mediationsverfahren ist deutlich <span class="bold">schneller</span>
          als ein Gerichtsverfahren.
        </li>
        <li>Der gemeinsam <span class="bold">gefundene Termin</span> kann meist
          kurzfristig vereinbart werden. Weitere
          Termine schließen sich zeitnah an, so dass je nach Umfang des Konflikts nach
          kurzer Zeit eine gemeinsame Lösung vereinbart werden kann.
        </li>
        <li>
          Die Mediation ist <span class="bold">kostengünstiger</span>
          als ein Gerichtsverfahren.
        </li>
        <li>Die Medianden erarbeiten <span class="bold">individuelle Lösungen,</span>
          die <span class="bold">ihren Interessen</span> entsprechen.
        </li>
      </ul>
      Nach einem Gerichtsverfahren gibt es häufig einen Gewinner und einen Verlierer, oft auch zwei
      Verlierer. Eins ist auf jeden Fall klar: Die Beziehung der Konfliktparteien ist meist für
      immer zerstört. Die Mediation führt weg vom Konflikt hin zu einem gegenseitigen Verständnis
      und zu einer gemeinsamen Lösung. Die Konfliktparteien können wieder miteinander sprechen und
      sich auf Augenhöhe begegnen. Die Konfliktfähigkeit der Streitparteien entwickelt sich weiter.

      <p class="quote">
        Jede Minute Deines Lebens, die Du im
        Streit verbringst, sind 60 verlorene Sekunden
      </p>

    </TextDrawer>
    <TextDrawer blue title="Für wen ist eine Mediation geeignet?">
      Mediation ist für jeden geeignet, der einen Konflikt hat, der ihn belastet und den
      er lösen möchte. Überall begegnen uns Auseinandersetzungen, das ist normal. Häufig
      sind die Streitparteien emotional nicht in der Lage, aus dem Konflikt selbständig
      herauszufinden. Ohne Hilfe eines unabhängigen Dritten kann der Streit immer weiter
      eskalieren. Hier hilft der Mediator als allparteilicher und unabhängiger Dritter,
      die beiden zerstrittenen Parteien wieder zu einem gegenseitigen Verständnis und
      zu einem Gespräch zu führen.
      <br><br>
      Hier einige Gebiete, in denen Mediation bereits durchgeführt wird:
      <ul>
        <li>Trennungs- und Scheidungs-Mediation,</li>
        <li>Familienstreitigkeiten,</li>
        <li>Erbschaftsstreitigkeiten,</li>
        <li>Streitigkeiten in der Schule/Schulkonflikte,</li>
        <li>Konflikte im Arbeitsalltag,</li>
        <li>Streitigkeiten im Mieter-/Vermieter-Verhältnis,</li>
        <li>Nachbarschaftsstreitigkeiten,</li>
        <li>Zivilrechtsstreitigkeiten allgemein.</li>
      </ul>
      Weitere Mediationen sind Interkulturelle Mediation, Wirtschaftsmediation, Teammediation,
      Mitarbeitermediation sowie Mediationen im öffentlichen Bereich oder Umweltmediationen.
    </TextDrawer>
    <TextDrawer title="Wie lange dauert eine Mediation?">
      Die Dauer hängt von der Anzahl der beteiligten Parteien sowie der Komplexität des Konfliktes
      ab. Je nach Konflikt kann bereits in einer Sitzung eine Lösung erarbeitet werden. Es
      können jedoch auch mehrere Termine vereinbart werden, soweit dies notwendig ist.
    </TextDrawer>
    <TextDrawer blue title="Was kostet eine Mediation?">
      Das Honorar richtet sich nach dem Zeitaufwand und wird individuell vereinbart.
      Viele Rechtschutzversicherungen sehen in ihren Versicherungsbedingungen eine
      Kostenübernahme für Mediation vor.
    </TextDrawer>
    <TextDrawer title="Unsere Mediatoren" id="mediators">
      <div class="mediators">
        <div class="mediator"
             v-for="(mediator, index) in jsonData"
             v-bind:key="index"
             v-on:click="goToMediator(mediator.slug)">
          <img v-bind:src="mediator.image" v-bind:alt="mediator.name">
          <p>{{ mediator.name }}</p>
        </div>
      </div>
    </TextDrawer>
    <div class="textpadding">
      <p>
        <span class="bold">
          Haben Sie einen Konflikt, der Sie belastet und Sie wünschen eine Lösung?
          <br>
          <br>
          Unser Netzwerk bietet Ihnen durch verschiedene berufliche und
          persönliche Hintergründe für jedes Anliegen den passenden Mediator.
          <br>
          Machen Sie den ersten Schritt! Nehmen Sie Kontakt
          mit uns auf und informieren Sie sich unverbindlich!
        </span>
      </p>
      <p class="quote">
        Den anderen verstehen heißt nicht damit einverstanden sein, was er macht und was er tut.
      </p>
    </div>
    <div id="footer">
      <div class="textpadding">
        <p>Made by Marc Radmacher</p>
        <a href="https://radmacher.club">radmacher.club</a>
        <div>
          <router-link to="/impressum">Impressum</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextDrawer from '../components/TextDrawer.vue';
import json from '../../public/mediators.json';

export default {
  name: 'Home',
  components: { TextDrawer },
  data() {
    return {
      jsonData: [],
    };
  },
  created() {
    this.jsonData = json.mediators;
  },
  methods: {
    goToMediator(slug) {
      this.$router.push({ name: slug });
    },
  },
};
</script>

<style scoped lang="less">
@import "../assets/styling/global";

.text-padding {
  padding: 0 60px 0 60px;
}

.VueCarousel-dot {
  background-color: rgb(222, 219, 219) !important;
}

.mediators {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  gap: 0 0;
  grid-template-areas:
        "mediator mediator";

  .mediator {
    img {
      width: 200px;
      height: 200px;
    }
  }
}

@media only screen and (min-width: 801px) {
  #mediators {
    display: none;
  }

  #footer {
    display: none;
  }
}

@media only screen and (max-width: 510px) {
  .mediators {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
    gap: 0 0;
    grid-template-areas:
        "mediator";
  }
}
</style>
