<template>
  <div class="sidebar">
    <div class="logo">
      <a v-on:click="goHome">
        <img alt="Mediation Niederrhein" src="@/assets/key.png" class="img">
        <h2>Mediation Niederrhein</h2>
      </a>
    </div>
    <div class="carousel">
      <carousel autoplay :per-page="perPage" pagination-color="#b5b5b5">
        <slide v-for="(mediator, index) in jsonData" v-bind:key="index">
          <div class="slide-content" v-on:click="goToMediator(index, mediator.slug)">
            <img v-bind:src="mediator.image" v-bind:alt="mediator.name">
            <h2>{{ mediator.name }}</h2>
          </div>
        </slide>
      </carousel>
    </div>

    <div class="copyright">
      <p>Made by<br>Marc Radmacher</p>
      <!-- <a href="https://radmacher.club" class="radmacherclub">radmacher.club</a> -->
      <br>
      <div>
        <router-link to="/impressum">Impressum</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import json from '../../public/mediators.json';

export default {
  name: 'SideBar',
  data() {
    return {
      jsonData: json.mediators,
      perPage: 1,
    };
  },
  methods: {
    goToMediator(id, slug) {
      this.$router.push({ name: slug });
      this.$router.go();
    },
    goHome() {
      this.$router.push({ name: 'Home' });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../assets/styling/global";

.sidebar {
  height: 100vh;
  width: 320px;
  background: #ffffff;
}

.logo {
  background-color: @light-blue;
  position: relative;
  top: auto;
  left: auto;
  text-align: center;
  padding: 30px 0;

  .img {
    width: 200px;
    height: 200px;
  }
}

.carousel {
  margin-top: 50px;

  .slide-content {
    cursor: pointer;
    margin: 0 auto;
    width: fit-content;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0 0;
    justify-items: center;
    align-items: center;
    grid-template-areas:
        "image"
        "name";

    img {
      width: 100px;
      height: 100px;
      text-align: center;
      grid-area: image;
    }

    h2 {
      text-align: center;
      grid-area: name;
    }
  }
}

.copyright {
  border-top: 1px solid #a2a2a2;
  padding: 0 25px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
  text-align: center;

  p {
    margin-bottom: 2px;
  }

  .radmacherclub {
    margin: 5px 0 15px;
  }
}
</style>
